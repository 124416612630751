import React, { useEffect, useRef, useState } from 'react'
import { Box, Button, FormControlLabel, Checkbox, Select, MenuItem, FormControl, InputLabel, CircularProgress } from '@mui/material'
import { AddCustomVarietes, getAllCrops, getAllMachines, getRequestConfigurations, getScanID, getUserScanMetaData, postCvScanResult, postScanFiles, postUserScanMetaData } from '../../utils/api'
import { sendInferenceRequest } from '../../utils/inferenceApi'
import { processImage } from '../../utils/processedImageFunction'
import WeightAndMoisture from '../../WeightAndMoisture'
import AddCustomVariety from '../../AddCustomVariety'
import ResultScreen from './ResultScreen'
import PopUpMessage from '../../widgets/PopUpMessage'
import ProcessingForm from './ProcessingForm';
import Slider from '@mui/material/Slider';
import InfoIcon from '@mui/icons-material/Info';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import AdvanceDropDown from '../../widgets/AdvanceDropDown';
import { getThresholdValues, variety_list_static_threshold } from '../../common/static_threshold_value';

export default function Dashboard() {
  const [crops, setCrops] = useState([])
  const [uniqueCropList, setUniqueCropList] = useState([])
  const [verietyListRelatedCrop, setVarietyListRelatedCrop] = useState([])
  const [machines, setMachines] = useState([])
  const [getUserMetaData, setGetUserMetaData] = useState([])
  const [selectedCrop, setSelectedCrop] = useState('')
  const [selectedVariety, setSelectedVariety] = useState('')
  const [selectedCapturedWebpImage, setSelectedCapturedWebpImage] = useState('')
  const [selectedMachine, setSelectedMachine] = useState('')
  const [isLoadingRequestConfigurations, setIsLoadingRequestConfigurations] = useState(false)
  const [scanId, setScanId] = useState(null)
  const [subScanId, setSubScanId] = useState(null)
  const [scanData, setScanData] = useState(null)
  const [isProcessing, setIsProcessing] = useState(false)
  const [file, setFile] = useState(null)
  const [browserLocation, setBrowserLoaction] = useState()
  const [processedImage, setProcessedImage] = useState(null);
  const [fileArray, setFileArray] = useState(null)
  const [title, setTitle] = useState('')
  const [message, SetMessage] = useState('')
  const [isMessageOpen, setIsMessageOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isErrorOpen, setIsErrorOpen] = useState(false)
  const [isAddMoistureAndWeight, setIsAddMoistureAndWeight] = useState(false)
  const [weight, setWeight] = useState('')
  const [moisture, setMoisture] = useState('')
  const [requestConfigurations, setRequestConfigurations] = useState(null)
  const [selectedOptions, setSelectedOptions] = useState({});
  const [visiblePDfButton, setVisiblePdfButton] = useState(false)
  const [isMetaDataServerResponce, setIsMetaDataServerResponce] = useState('')
  const [isCvInferenceResponce, setIsCvInferenceResponce] = useState(false)
  const [updatedUserMetaData, setUpdatedUserMetaData] = useState('')
  const fileInputRef = useRef(null);
  const [chalkyArea, setchalkyArea] = useState(50);
  const [discolorArea, setdiscolorArea] = useState(10);
  const [chalkyQuality, setchalkyQuality] = useState(50);
  const [discolorQuality, setdiscolorQuality] = useState(20);
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorForDiscolor, setAnchorForDiscolor] = useState(null);
  const [openChalky, setOpenChalky] = useState(false);
  const [openDiscolor, setOpenDiscolor] = useState(false);
  const [cropname, setCropname] = useState(null);
  const [originalImage, setOriginalImage] = useState(null);
  const [isModalOpen, setModalOpen] = useState(false);
  const [heightOfKernel, setHeightOfKernel] = useState('');
  const [poniyaThreshold, setPoniyaThreshold] = useState('');
  const [brokenThreshold, setBrokenThreshold] = useState('');
  const [addThreshold, setAddThreshold] = useState(false);
  const [isChecked, setChecked] = useState(false);
  const [chalkyAreaForVariety, setchalkyAreaForVariety] = useState(50);
  const [discolorAreaForVariety, setdiscolorAreaForVariety] = useState(10);
  const [chalkyQualityForVariety, setchalkyQualityForVariety] = useState(50);
  const [discolorQualityForVariety, setdiscolorQualityForVariety] = useState(20);
  const [selectedValues, setSelectedValues] = useState({});

  const handleSelectChange = (keyName, value) => {
    setSelectedValues(prevState => ({
      ...prevState,
      [keyName]: value,
    }));
  };

  const handleCheckboxChange = () => {
    setAddThreshold(!addThreshold);
  };

  const handleButtonClick = (event) => {
    setOpenChalky(true); // Open the chalky popover
    setAnchorEl(event.currentTarget);
  };
  const openModal = () => {
    setModalOpen(true);
  };

  const handleButtonClickForDiscolor = (event) => {
    setOpenDiscolor(true)
    setAnchorForDiscolor(event.currentTarget);
  };
  useEffect(() => {
    setValuesFromVariety(selectedVariety?.variety);
  }, [selectedVariety,]);

  // useEffect(() => {
  //   console.log(selectedVariety,selectedMachine);
  //   const response = await axios GetRequestConfig(selectedVariety?.variety);
  // }, [selectedVariety, selectedMachine]);

  function setValuesFromVariety(variety) {
    if (variety?.includes("_a") && variety.includes("_b") && variety.includes("_c") && variety.includes("_d")) {
      let aMatch = variety.match(/_a(\d+)/);
      let bMatch = variety.match(/_b(\d+)/);
      let cMatch = variety.match(/_c(\d+)/);
      let dMatch = variety.match(/_d(\d+)/);
      if (aMatch) {
        setchalkyArea(parseInt(aMatch[1]));
      }

      if (bMatch) {
        setchalkyQuality(parseInt(bMatch[1]));
      }

      if (cMatch) {
        setdiscolorArea(parseInt(cMatch[1]));
      }

      if (dMatch) {
        setdiscolorQuality(parseInt(dMatch[1]));
      }
    } else {
      setchalkyArea(50);
      setdiscolorArea(10);
      setchalkyQuality(50);
      setdiscolorQuality(20)
    }
  }

  const resetSliders = () => {
    setchalkyArea(50);
    setdiscolorArea(10);
    setchalkyQuality(50);
    setdiscolorQuality(20)
  };
  const handleClose = () => {
    setAnchorEl(null);
    setOpenChalky(false);
  };
  const handleCloseForDiscolor = () => {
    setAnchorForDiscolor(null);
    setOpenDiscolor(false);
  };
  const handleSliderforChalkyArea = (event, newValue) => {
    setchalkyArea(newValue);
  };
  const handleSliderForChalkyQuality = (event, newValue) => {
    setchalkyQuality(newValue);
  };
  const handleSliderforDiscolourArea = (event, newValue) => {
    setdiscolorArea(newValue);
  };
  const handleSliderforDiscolourQuality = (event, newValue) => {
    // if (newValue >= 0 && newValue <= 40) {
    //   alert("Slider value is between 0 and 40!");
    //   setdiscolorQuality(40);
    //   return;
    // }
    setdiscolorQuality(newValue);
  };
  const handleSliderforChalkyAreaForVariety = (event, newValue) => {
    setchalkyAreaForVariety(newValue);
  };
  const handleSliderForChalkyQualityForVariety = (event, newValue) => {
    setchalkyQualityForVariety(newValue);
  };
  const handleSliderforDiscolourAreaForVariety = (event, newValue) => {
    setdiscolorAreaForVariety(newValue);
  };
  const handleSliderforDiscolourQualityForVariety = (event, newValue) => {
    setdiscolorQualityForVariety(newValue);
  };
  useEffect(() => {
    const browserLocation = {
      longitude: 0,
      latitude: 0
    }
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((location) => {
        let lat = location.coords.latitude;
        let long = location.coords.longitude;
        browserLocation.latitude = lat;
        browserLocation.longitude = long;
        setBrowserLoaction(browserLocation)
      })
    }
  }, [])


  useEffect(() => {
    const fetchData = async () => {
      try {
        const [cropResponse, machineResponce, userMetaDataResponce] = await Promise.all([
          getAllCrops(),
          getAllMachines(),
          getUserScanMetaData(),
        ])
        if (cropResponse?.data?.data) {
          setCrops(cropResponse?.data?.data?.crops);
          const crop_list = cropResponse?.data?.data?.crops
          const uniqueObjects = crop_list?.length && crop_list.reduce((unique, currentObj) => {
            const isDuplicate = unique.some(obj => obj.crop === currentObj.crop)
            if (!isDuplicate) { unique.push(currentObj) }
            return unique;
          }, []);
          setUniqueCropList(uniqueObjects);
        } else {
          onHandleResponceError(cropResponse?.response?.status, cropResponse?.response?.data?.message)
        }
        if (machineResponce?.data?.data) {
          setMachines(machineResponce.data.data.machines_data);
        } else {
          onHandleResponceError(machineResponce?.response?.status, machineResponce?.response?.data?.message)
        }
        if (userMetaDataResponce?.data?.data) {
          setGetUserMetaData(userMetaDataResponce.data.data.metadata);
        } else {
          onHandleResponceError(userMetaDataResponce?.response?.status, userMetaDataResponce?.response?.data?.message)
        }
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, [])
  // call post file & cv result api
  useEffect(() => {
    (async () => {
      if (scanData) {
        const scan_id = scanId;
        const sub_scan_id = subScanId;
        let scan_file, scan_cv_result
        try {
          if (isMetaDataServerResponce === 200) {
            for (let i = 0; i < 2; i++) {
              [scan_file, scan_cv_result] = await Promise.all([
                postScanFiles(scan_id, sub_scan_id, fileArray),
                postCvScanResult(scan_id, sub_scan_id, scanData),
              ]);

              if (scan_file?.status === 200 && scan_cv_result?.status === 200) {
                setVisiblePdfButton(true)
                break;
              }
            }
          } else {
            for (let i = 0; i < 2; i++) {
              [scan_file, scan_cv_result] = await Promise.all([
                postScanFiles(scan_id, sub_scan_id, fileArray),
                postCvScanResult(scan_id, sub_scan_id, scanData),
              ]);
              if (scan_file?.status === 200 && scan_cv_result?.status === 200) {
                break;
              }
            }
          }
          if (scan_file?.status !== 200) {
            onHandleResponceError(scan_file?.response?.status, scan_file?.response?.data?.message)
          } else if (scan_cv_result?.status !== 200) {
            onHandleResponceError(scan_cv_result?.response?.status, scan_cv_result?.response?.data?.message)
          }

        } catch (error) {
          console.log(error);
        } finally {
          if (fileInputRef.current) {
            fileInputRef.current.value = '';
          }
          setFile(null)
        }
      }
    })();
  }, [scanData]);

  useEffect(() => {
    if (selectedCrop) {
      const verity_list = crops.length && crops.filter(crop => (
        crop.crop === selectedCrop.crop
      ))
      setVarietyListRelatedCrop(verity_list);
      setSelectedVariety('')
      setSelectedValues({})
      handleRefreshClick()
    } else {
      setVarietyListRelatedCrop([]);
    }
  }, [selectedCrop])

  const fetchVarietyListRelatedCrop = async () => {
    try {
      if (selectedCrop) {
        const response = await getAllCrops()
        if (response?.data?.data) {
          setCrops(response?.data?.data?.crops);
          const crop_list = response?.data?.data?.crops
          const uniqueObjects = crop_list?.length && crop_list.reduce((unique, currentObj) => {
            const isDuplicate = unique.some(obj => obj.crop === currentObj.crop)
            if (!isDuplicate) { unique.push(currentObj) }
            return unique;
          }, []);
          setUniqueCropList(uniqueObjects);
          const varietyList = response.data.data.crops;
          const varietyListRelatedCrop = crops.length && varietyList.filter(variety => variety.crop === selectedCrop.crop);
          setVarietyListRelatedCrop(varietyListRelatedCrop);
          setSelectedVariety('')
          // handleRefreshClick()
        }
      } else {
        setVarietyListRelatedCrop([]);
      }
    } catch (error) {
      console.error('Error fetching variety list:', error);
    }
  };

  useEffect(() => {
    (async () => {
      selectedVariety && await handleCropChange(selectedVariety.id)
    })()
  }, [selectedVariety])

  useEffect(() => {
    const fetchConfigurations = async () => {
      if (selectedMachine && selectedVariety) {
        try {
          await onGetRequestConfigurations(selectedVariety.id, selectedMachine.machine_code);
        } catch (error) {
          console.error('Error fetching configurations:', error);
        }
      }
    };

    fetchConfigurations();
  }, [selectedVariety, selectedMachine]);

  const onHandleResponceError = (code, message) => {
    // console.log(message, code);
    message && setIsErrorOpen(true)

    if (code === 404) {
      message = 'Error bad request or wrong url'
    }
    setTitle('Alert')
    SetMessage(message)
  }

  const getMetaData = () => {
    const scan_metadata = {
      build_code: '',
      device_info: 'Browser',
      build_version: '',
      locationType: "Last_Known",
      build_type: (window.config.inferenceURL).includes('dev') ? 'Staging' : 'Production',
      longitude: browserLocation?.longitude ? browserLocation.longitude : 0,
      latitude: browserLocation?.latitude ? browserLocation.latitude : 0,
    }
    if (weight !== '') {
      scan_metadata.weight = weight;
    }

    if (moisture !== '') {
      scan_metadata.moisture = moisture;
    }
    if (cropname?.crop === 'Rice' && selectedMachine.machine_code === 'M3') {
      let threshold_value = getThresholdValues(cropname?.variety);
      if (!threshold_value) {
        threshold_value = {
          discolor_quality: discolorQuality / 100,
          discolor_area: discolorArea / 100,
          chalky_quality: chalkyQuality / 100,
          chalky_area: chalkyArea / 100,
        }
      }
      scan_metadata.discolor_quality = threshold_value.discolor_quality;
      scan_metadata.chalky_quality = threshold_value.chalky_quality;
      scan_metadata.discolor_area = threshold_value.discolor_area;
      scan_metadata.chalky_area = threshold_value.chalky_area;
    }
    return scan_metadata
  };


  const handleCropChange = async (selectedCropId) => {
    const crop_object = crops.find(crop => crop.id === selectedCropId)
    setCropname(crop_object)
    handleRefreshClick()
    setRequestConfigurations(null)
    setSelectedOptions({})
    setSelectedValues({})
  }

  const onGetRequestConfigurations = async (selectedCropId, selectedMachineId) => {
    try {
      setIsLoadingRequestConfigurations(true)
      const response = (selectedCropId && selectedMachineId) && await getRequestConfigurations(selectedCropId, selectedMachineId)
      setRequestConfigurations(response?.data?.data?.requestConfigurations)
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoadingRequestConfigurations(false)
    }
  }

  const handleDropdownChange = (key, value) => {
    setSelectedOptions((prevOptions) => ({
      ...prevOptions,
      [key]: value.toLowerCase(),
    }));
  };
  function removeExifOrientation(imageFile) {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = function () {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        let width = img.width;
        let height = img.height;
        canvas.width = width;
        canvas.height = height;

        ctx.drawImage(img, 0, 0, width, height);
        canvas.toBlob((blob) => {
          resolve(blob);
        }, imageFile.type);
      };

      const reader = new FileReader();
      reader.onload = function (e) {
        img.src = e.target.result;
      };

      reader.readAsDataURL(imageFile);
    });
  }


  const handleFileChange = async (event) => {
    setFile(null);
    setScanData(null)
    setProcessedImage(null)
    setSelectedCapturedWebpImage(null)
    setFileArray(null)
    setMoisture('')
    setWeight('')

    const selectedFile = event.target.files[0];
    setOriginalImage(selectedFile)
    setSelectedCapturedWebpImage(selectedFile)
    if (selectedFile) {
      removeExifOrientation(selectedFile)
        .then((processedImageBlob) => {
          // setSelectedCapturedWebpImage(processedImageBlob)
        })
        .catch((error) => {
          console.error('Error processing image:', error);
        });
    }
    // Create an object URL for the selected file
    const inputImageUrl = URL.createObjectURL(selectedFile);

    // Create an image element to load the input image
    const img = new Image();
    img.crossOrigin = 'anonymous'; // Set cross-origin attribute if necessary
    img.src = inputImageUrl;

    img.onload = function () {
      const canvas = document.createElement('canvas');
      canvas.width = img.width;
      canvas.height = img.height;

      const context = canvas.getContext('2d');
      context.drawImage(img, 0, 0, img.width, img.height);

      // Convert the canvas content to a WebP image with quality 50
      canvas.toBlob(function (blob) {
        // Create a new Blob with the desired file name
        const webpBlob = new Blob([blob], { type: 'application/octet-stream' });
        const webpUrl = URL.createObjectURL(webpBlob);

        // Set the WebP image details to the state
        const webpFile = new File([webpBlob], 'captured.webp', {
          type: 'application/octet-stream',
        });

        // setSelectedCapturedWebpImage(webpFile)
      }, 'image/webp', 0.5);
    };
    // setSelectedCapturedWebpImage(selectedFile)
    setFile(selectedFile);
  };

  const onCallPostUserMetaData = async (scan_id, sub_scan_id) => {
    try {
      let metaData = getMetaData()
      metaData.user_metadata = {
        ...metaData.user_metadata,
        ...updatedUserMetaData
      };
      const meta_data_responce = await postUserScanMetaData(scan_id, sub_scan_id, metaData)
      if (meta_data_responce?.status === 200) {
        setIsMetaDataServerResponce(meta_data_responce?.status)
      } else {
        setIsProcessing(false)
        onHandleResponceError(meta_data_responce?.response?.status, meta_data_responce?.response?.data?.message)
        return
      }
    } catch (error) {
      console.log(error);
    }
  }
  const saveTestVariety = async () => {
    setTitle('')
    SetMessage('')
    setIsMessageOpen(true)
    setIsLoading(true)
    const crop_name = selectedCrop?.crop;
    const variety_name = selectedVariety?.variety
    const companyid = localStorage.getItem('company_id');
    const firstOccurrenceIndex = variety_name.indexOf("_a");
    // Check if "_a" was found
    let processed_variety_name = firstOccurrenceIndex !== -1
      ? variety_name.substring(0, firstOccurrenceIndex)
      : variety_name;
    const newVarietyName = `${processed_variety_name}_a${chalkyArea}_b${chalkyQuality}_c${discolorArea}_d${discolorQuality}`;
    try {
      const response = await AddCustomVarietes(crop_name, variety_name, companyid, newVarietyName);
      if (response.status === 200) {
        fetchVarietyListRelatedCrop()
        setTitle('Sucess')
        SetMessage(response?.data?.message)
      }
      if (response.status === 400) {
        setTitle('Alert')
        SetMessage(response?.data?.message)
      }
      setIsLoading(false)
    } catch (error) {
      console.error('Error:', error);
    }
  }
  const handleSubmitClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
    setVisiblePdfButton(false)
    setScanData(null)
    setIsProcessing(true)
    setIsCvInferenceResponce(false)
    setIsMetaDataServerResponce(false)
  }
  const handleTestClick = () => {
    setChecked(!isChecked);
  }
  function getVarietyCode(crop, variety) {
    const pm = {
      cropType: crop, // Replace with your crop type value
      cropVariety: variety, // Replace with your crop variety value
    };
    if (pm.cropType === 'Rice' && pm.cropVariety.includes('Perfect')) return 'v0';
    else if (pm.cropType === 'Rice' && pm.cropVariety === 'IR8') return 'v1';
    else if (pm.cropType === 'Rice' && pm.cropVariety === 'gujrati') return 'v2';
    else if (pm.cropType === 'Rice' && pm.cropVariety === 'gujarat') return 'v2';
    else if (pm.cropType === 'Rice' && pm.cropVariety === 'tukdi') return 'v3';
    else if (pm.cropType === 'Rice' && pm.cropVariety === 'sella') return 'v4';
    else if (pm.cropType === 'Rice' && pm.cropVariety === 'steam') return 'v5';
    else if (pm.cropType === 'Rice' && pm.cropVariety === 'hybrid') return 'v6';
    else if (pm.cropType === 'Rice' && pm.cropVariety === 'basmati') return 'v7';
    else if (pm.cropType === 'Rice' && pm.cropVariety === 'gujrati_boiled') return 'v8';
    else if (pm.cropType === 'Rice' && pm.cropVariety === 'delight_basmati') return 'v9';
    else if (pm.cropType === 'Rice' && pm.cropVariety === 'raj_rani') return 'v10';
    else if (pm.cropType === 'Rice' && pm.cropVariety === 'IR64') return 'v11';
    else if (pm.cropType === 'Rice' && pm.cropVariety === 'IR64_boiled') return 'v12';
    else if (pm.cropType === 'Rice' && pm.cropVariety === 'bori_basmati') return 'v13';
    else if (pm.cropType === 'Rice' && pm.cropVariety === 'sriram') return 'v14';
    else if (pm.cropType === 'Arhar' && pm.cropVariety === 'default') return 'v1';
    else if (pm.cropType === 'Arhar' && pm.cropVariety === 'white') return 'v2';
    else if (pm.cropType === 'Arhar' && pm.cropVariety === 'split_arhar') return 'v3';
    else if (pm.cropType === 'Urad' && pm.cropVariety === 'default') return 'v1';
    else if (pm.cropType === 'Urad' && pm.cropVariety === 'brown') return 'v2';
    // Default value if no matching combination is found
    return 'v1';
  }
  const getOriginalFileName = (file) => {
    if (file) {
      const fileNameParts = file.name.split('.');
      const extension = fileNameParts.pop();
      return `original.${extension}`;
    }
    return null;
  };

  const onCallInferenceServer = async () => {
    try {
      //  scan is api call
      const res = await getScanID(selectedVariety.id, selectedMachine.machine_code)

      if (res?.data?.data) {
        setScanId(res?.data?.data.scan_id)
        setSubScanId(res?.data?.data.sub_scan_id)
      } else {
        setIsProcessing(false)
        onHandleResponceError(res?.response?.status, res?.response?.data?.message)
        return
      }
      // await onCallPostUserMetaData(res?.data?.data.scan_id, res?.data?.data.sub_scan_id)
      //  inference api call
      const crop = crops.find(crop => crop.id === selectedVariety.id)
      // const originalFile = file
      const variety_code = getVarietyCode(crop?.crop, crop?.variety)
      let threshold_value = getThresholdValues(crop?.variety)
      if (!threshold_value) {
        threshold_value = {
          discolor_quality: discolorQuality / 100,
          discolor_area: discolorArea / 100,
          chalky_quality: chalkyQuality / 100,
          chalky_area: chalkyArea / 100,
        }
      }

      const crop_variety_name = crop?.variety
      const crop_name = crop?.crop
      const lowercase_crop_name = crop_name.toLowerCase();
      const lowercase_crop_variety_name = crop_variety_name.toLowerCase();
      const firstOccurrenceIndex = lowercase_crop_variety_name.indexOf("_a");
      // Check if "_a" was found
      let processed_variety_name = firstOccurrenceIndex !== -1
        ? lowercase_crop_variety_name.substring(0, firstOccurrenceIndex)
        : lowercase_crop_variety_name;
      const scanId = res?.data?.data.scan_id
      const subScanId = res?.data?.data.sub_scan_id
      const inferenceResponce = await sendInferenceRequest(variety_code, lowercase_crop_name, processed_variety_name, weight, selectedMachine.machine_code, selectedCapturedWebpImage, selectedOptions,
        threshold_value.discolor_quality, threshold_value.chalky_quality, threshold_value.discolor_area, threshold_value.chalky_area, scanId, subScanId, selectedValues)
      const inferenceStatus = inferenceResponce?.status
      const inferenceJsonData = await inferenceResponce.json()
      const jsonBlob = new Blob([JSON.stringify(inferenceJsonData)], { type: 'application/json' });
      const jsonFile = new File([jsonBlob], 'response.json', { type: 'application/json' });
      if (inferenceStatus === 200) {
        setIsCvInferenceResponce(true)

        //  call meta data api
        await onCallPostUserMetaData(res?.data?.data.scan_id, res?.data?.data.sub_scan_id)
        // processed Image
        const processedImgData = await processImage(selectedCapturedWebpImage, inferenceJsonData?.processed_image);
        setProcessedImage(processedImgData);
        // const originalFileName = getOriginalFileName(originalImage);
        // const originalFileData = new File([file], originalFileName, {
        //   type: originalImage.type,
        // });
        // captured image
        const capturedWepImageData = new File([file], 'captured.webp', {
          type: 'application/octet-stream',
        });
        setFileArray([capturedWepImageData, processedImgData, jsonFile])
        inferenceJsonData['success'] = true;
        setScanData(inferenceJsonData)

        // alert box loop
        if (inferenceJsonData?.alerts?.alert_keys?.length > 0) {
          let warningMessage = ""
          inferenceJsonData?.alerts?.alert_keys?.map((alert) => {
            warningMessage += inferenceJsonData?.alerts?.alert_messages[alert]?.message
            warningMessage += '\n'
          })
          setTitle('Warning')
          SetMessage(warningMessage)
          setIsErrorOpen(true)
        }
      } else {
        inferenceJsonData['success'] = false;
        setScanData(inferenceJsonData)
        const capturedWepImageData = new File([file], 'captured.webp', {
          type: 'application/octet-stream',
        });
        setFileArray([capturedWepImageData, jsonFile])
        setTitle('Alert')
        if (inferenceStatus === 400) {
          SetMessage(inferenceJsonData?.reason || inferenceJsonData?.message)
        } else if (inferenceStatus === 413) {
          SetMessage('Capture image is to large.')
        } else if (inferenceStatus === 500) {
          SetMessage(inferenceJsonData?.message)
        } else if (inferenceStatus === 502) {
          SetMessage('Server is shutdown or Restarting, Please try again after 10 Minute or in between 8 AM to 8 PM.')
        } else if (inferenceStatus === 503) {
          SetMessage('Server is restarting, Please try again after 10 to 15 minute.')
        }
        setSelectedCapturedWebpImage(null)
        setIsErrorOpen(true)
        setFile(null)
      }
      setIsProcessing(false)
    } catch (error) {
      console.error(error);
      onHandleResponceError(500, 'Internal Server Error, please contact the admin')
      setIsProcessing(false)
    }
  }
  const handleCloseForVariety = () => {
    setModalOpen(false)
    setPoniyaThreshold('')
    setHeightOfKernel('')
    setBrokenThreshold('')
    setAddThreshold(false)
    setchalkyAreaForVariety(50);
    setdiscolorAreaForVariety(10);
    setchalkyQualityForVariety(50);
    setdiscolorQualityForVariety(20)
  };

  const handleSave = async () => {
    if (heightOfKernel === '' || poniyaThreshold === '' || brokenThreshold === '') {
      alert('Please fill in all required fields.');
      return;
    }
    handleCloseForVariety()
    setTitle('')
    SetMessage('')
    setIsMessageOpen(true)
    setIsLoading(true)
    const crop_name = selectedCrop?.crop;
    let variety_name;
    const hasDecimalPoint = String(heightOfKernel).includes('.');
    const heightFloat = hasDecimalPoint ? heightOfKernel : heightOfKernel + '.0';
    const formattedPoniyaThreshold = String(poniyaThreshold).replace(/^0+/, '').padStart(2, '0');
    const formattedBrokenThreshold = String(brokenThreshold).replace(/^0+/, '').padStart(2, '0');

    if (addThreshold) {
      variety_name = `Perfect${heightFloat}mm_P${formattedPoniyaThreshold}_B${formattedBrokenThreshold}_a${chalkyAreaForVariety}_b${chalkyQualityForVariety}_c${discolorAreaForVariety}_d${discolorQualityForVariety}`;
    } else {
      variety_name = `Perfect${heightFloat}mm_P${formattedPoniyaThreshold}_B${formattedBrokenThreshold}`;
    }

    const companyid = localStorage.getItem('company_id');
    try {
      const response = await AddCustomVarietes(crop_name, variety_name, companyid);
      if (response.status === 200) {
        fetchVarietyListRelatedCrop()
        setTitle('Success')
        SetMessage(response?.data?.message)
      }
      if (response.status === 400) {
        setTitle('Alert')
        SetMessage(response?.data?.message)
      }
      setIsLoading(false)
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const handleRefreshClick = async () => {
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
    setFile(null)
    setVisiblePdfButton(false)
    setMoisture('')
    setWeight('')
    setScanId(null)
    setSubScanId(null)
    setProcessedImage(null)
    setScanData(null)
    setFileArray(null)
    setSelectedCapturedWebpImage(null)
    setChecked(false)
  };
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <div className='m-20'>
      <div >
        <div>
          <WeightAndMoisture
            open={isAddMoistureAndWeight}
            setOpen={setIsAddMoistureAndWeight}
            weight={weight}
            setWeight={setWeight}
            moisture={moisture}
            setMoisture={setMoisture}
          />

          <PopUpMessage
            open={isErrorOpen}
            setOpen={setIsErrorOpen}
            title={title}
            message={message}
          />
          <PopUpMessage
            open={isMessageOpen}
            title={title}
            isLoading={isLoading}
            setOpen={setIsMessageOpen}
            message={message}
          />
          <ProcessingForm
            isProcessing={isProcessing}
            isCvInferenceResponce={isCvInferenceResponce}
            getUserMetaData={getUserMetaData}
            isMetaDataServerResponce={isMetaDataServerResponce}
            setUpdatedUserMetaData={setUpdatedUserMetaData}
            setIsProcessing={setIsProcessing}
            onCancleClick={handleRefreshClick}
            onCallInferenceServer={onCallInferenceServer}
            cropname={cropname}
          />

          <Box>
            <Box sx={{ gap: 1 }} className='d-flex justify-between flex-direction-custom'>
              <div>
                <div style={{ textAlign: 'start', margin: 5 }}><p style={{ display: 'inline', justifyContent: 'start' }}><span>Crop</span><span style={{ color: 'red' }}>*</span></p></div>
                <AdvanceDropDown
                  title="Crop"
                  width={200}
                  data_list={uniqueCropList}
                  unique_key={'id'}
                  show_options_item={(option) => `${option.crop}`}
                  show_name_on_drop_down_box={'crop'}
                  filter_items={['crop']}
                  get_selected_data={setSelectedCrop}
                  required={true}
                />
              </div>

              <div>
                <div style={{ textAlign: 'start', margin: 5 }}><p style={{ display: 'inline', justifyContent: 'start' }}><span>Variety</span><span style={{ color: 'red' }}>*</span></p></div>
                <AdvanceDropDown
                  title="Variety"
                  width={200}
                  data_list={verietyListRelatedCrop}
                  unique_key={'id'}
                  show_options_item={(option) => `${option.variety}`}
                  show_name_on_drop_down_box={'variety'}
                  filter_items={['variety']}
                  get_selected_data={setSelectedVariety}
                  reset_drop_down={selectedVariety === ''}
                  required={true}
                />
              </div>
              <div style={{ marginTop: "40px" }}>
                {selectedCrop?.crop === 'Rice' && (
                  <Button variant="contained" color="primary" onClick={openModal}>
                    <span role="img" aria-label="plus icon">+</span>
                  </Button>
                )}
              </div>
              <div >
                <div style={{ textAlign: 'start', margin: 5 }}><p style={{ display: 'inline', justifyContent: 'start' }}><span>Machine</span><span style={{ color: 'red' }}>*</span></p></div>
                <AdvanceDropDown
                  title="Machine"
                  width={200}
                  data_list={machines.filter(item => item.machine_code === 'M3' || item.machine_code === 'M2')}
                  unique_key={'machine_code'}
                  show_options_item={((option) => `${option.machine_name} ${option.machine_code}`)}
                  show_name_on_drop_down_box={'machine_name'}
                  filter_items={['machine_code']}
                  get_selected_data={setSelectedMachine}
                  required={true}
                />
              </div>

              <div>
                <div style={{ textAlign: 'start', margin: 5 }}><p style={{ display: 'inline', justifyContent: 'start' }}><span>Upload File</span><span style={{ color: 'red' }}>*</span></p></div>
                <input
                  type='file'
                  title="Upload File"
                  required={true}
                  className='rs-width-100 cursor'
                  style={{ padding: 15, borderRadius: 5, border: '1px solid skyblue' }}
                  onChange={handleFileChange}
                  ref={fileInputRef}
                />
              </div>
              <Box>
                <Button className='rs-width-100 m-t-20 buttonStyle' variant="contained" onClick={() => setIsAddMoistureAndWeight(true)}>Add Weight & Moisture</Button>
              </Box>
            </Box>
            {isModalOpen &&
              <AddCustomVariety
                open={isModalOpen}
                setOpen={setModalOpen}
                handleSave={handleSave}
                handleClose={handleCloseForVariety}
                heightOfKernel={heightOfKernel}
                setHeightOfKernel={setHeightOfKernel}
                PoniyaThreshold={poniyaThreshold}
                setPoniyaThreshold={setPoniyaThreshold}
                brokenThreshold={brokenThreshold}
                setBrokenThreshold={setBrokenThreshold}
                selectedCrop={selectedCrop}
                handleCheckboxChange={handleCheckboxChange}
                addThreshold={addThreshold}
                setAddThreshold={setAddThreshold}
                chalkyAreaForVariety={chalkyAreaForVariety}
                discolorAreaForVariety={discolorAreaForVariety}
                chalkyQualityForVariety={chalkyQualityForVariety}
                discolorQualityForVariety={discolorQualityForVariety}
                resetSliders={resetSliders}
                handleSliderforChalkyAreaForVariety={handleSliderforChalkyAreaForVariety}
                handleSliderForChalkyQualityForVariety={handleSliderForChalkyQualityForVariety}
                handleSliderforDiscolourAreaForVariety={handleSliderforDiscolourAreaForVariety}
                handleSliderforDiscolourQualityForVariety={handleSliderforDiscolourQualityForVariety}
              />}
            <Box sx={{ marginTop: 2 }}>
              {cropname?.crop === 'Rice' && !variety_list_static_threshold.includes(cropname?.variety) && selectedMachine.machine_code === 'M3' && selectedVariety && (
                <>
                  <div style={{ display: "flex", gap: "20px" }}>
                    <div>
                      <div>
                        <label><strong>Chalky threshold</strong></label>
                      </div>
                      <div style={{ display: 'flex', alignItems: 'center', marginTop: '30px' }}>
                        <span style={{ marginRight: '10px' }}>Area %</span>
                        <Slider
                          value={chalkyArea}
                          onChange={handleSliderforChalkyArea}
                          aria-label="Slider 1"
                          valueLabelDisplay="on"
                          valueLabelFormat={(value) => <span>{value}%</span>}
                          min={0}
                          max={100}
                          style={{ width: '200px' }}
                        />
                      </div>
                    </div>
                    <div>
                      <div style={{ display: 'flex', alignItems: 'center', marginTop: '45px' }}>
                        <span style={{ marginRight: '10px' }}>Quality %</span>
                        <Slider
                          value={chalkyQuality}
                          onChange={handleSliderForChalkyQuality}
                          aria-label="Slider 2"
                          valueLabelDisplay="on"
                          valueLabelFormat={(value) => <span>{value}%</span>}
                          min={0}
                          max={100}
                          style={{ width: '200px' }}
                        />
                        <Button aria-label="Information" color="secondary" onClick={handleButtonClick}>
                          <InfoIcon />
                        </Button>
                        <Popover
                          open={openChalky}
                          anchorEl={anchorEl}
                          onClose={handleClose}
                          anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                          }}
                        >
                          <Paper style={{ padding: '10px', maxWidth: '300px' }}>
                            <Typography>
                              If selected or larger area comes in one kernel, then consider it as chalky.
                            </Typography>
                          </Paper>
                        </Popover>
                      </div>
                    </div>
                    <div style={{ alignItems: 'center', marginTop: '45px' }}>
                      <Button variant="contained" style={{ backgroundColor: '#00838f', color: 'white' }} onClick={resetSliders}>
                        RESET
                      </Button>
                    </div>
                  </div>
                  <div style={{ display: "flex", gap: "20px", marginTop: '20px' }}>
                    <div>
                      <div>
                        <label><strong>Discolour threshold</strong></label>
                      </div>
                      <div style={{ display: 'flex', alignItems: 'center', marginTop: '30px' }}>
                        <span style={{ marginRight: '10px' }}>Area %</span>
                        <Slider
                          value={discolorArea}
                          onChange={handleSliderforDiscolourArea}
                          aria-label="Slider 1"
                          valueLabelDisplay="on"
                          valueLabelFormat={(value) => <span>{value}%</span>}
                          min={0}
                          max={100}
                          style={{ width: '200px' }}
                        />
                      </div>
                    </div>
                    <div>
                      <div style={{ display: 'flex', alignItems: 'center', marginTop: '45px' }}>
                        <span style={{ marginRight: '10px' }}>Quality %</span>
                        <Slider
                          value={discolorQuality}
                          onChange={handleSliderforDiscolourQuality}
                          aria-label="Slider 2"
                          valueLabelDisplay="on"
                          valueLabelFormat={(value) => <span>{value}%</span>}
                          min={0}
                          max={100}
                          style={{ width: '200px' }}
                        />
                        <Button aria-label="Information" color="secondary" onClick={handleButtonClickForDiscolor}>
                          <InfoIcon />
                        </Button>
                        <Popover
                          open={openDiscolor}
                          anchorEl={anchorForDiscolor}
                          onClose={handleCloseForDiscolor}
                          anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                          }}
                        >
                          <Paper style={{ padding: '10px', maxWidth: '300px' }}>
                            <Typography>
                              If selected or larger area comes in one kernel, then consider it as discolour.
                            </Typography>
                          </Paper>
                        </Popover>
                      </div>
                    </div>
                  </div>
                </>
              )
              }
            </Box>
            <Box display="flex" alignItems="center" gap={4} mb={2} mt={4}>
              {!isLoadingRequestConfigurations ? <>
                {selectedMachine.machine_code === 'M2' && requestConfigurations && requestConfigurations.length > 0 &&
                  requestConfigurations.map((config) => (
                    <Box key={config._id} width="20%">
                      <Typography variant="subtitle1" sx={{ textAlign: 'start', margin: 1 }}>
                        {config.display_name}<span style={{ color: 'red' }}>*</span>
                      </Typography>
                      <FormControl size="large" fullWidth>
                        <InputLabel>{config.display_name}</InputLabel>
                        <Select
                          value={selectedValues[config.key_name] || ""}
                          onChange={(e) => handleSelectChange(config.key_name, e.target.value)}
                          label={config.display_name}
                        >
                          <MenuItem value="" disabled>Select an option</MenuItem>
                          {config.values.map((value, i) => (
                            <MenuItem key={i} value={value}>
                              {value}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Box>
                  ))}
              </> : <div className='d-flex justify-start m-t-10'><CircularProgress size={30} /></div>}
            </Box>
            <Box className='d-flex justify-end m-r-15  flex-direction-custom m-t-10' sx={{ gap: 1 }}>
              <Button variant="contained" onClick={handleSubmitClick} disabled={!(selectedCrop && selectedMachine && selectedCapturedWebpImage && file) || scanData && Object.keys(scanData)?.length > 0} >Submit</Button>
              {cropname?.crop === 'Rice' && (
                <FormControlLabel
                  control={<Checkbox checked={isChecked} onChange={handleTestClick} color="primary" />}
                  label="Test"
                  disabled={!(selectedCrop && selectedMachine && selectedCapturedWebpImage && file) || scanData && Object.keys(scanData)?.length > 0}
                />
              )}
              <Button variant="contained" className="resetButtonStyle" onClick={handleRefreshClick} style={{ fontSize: '0.8rem', backgroundColor: 'red' }}> Reset </Button>
            </Box>
          </Box>
        </div>

      </div>

      <div className='m-t-20'>
        <div>
          {scanData && <ResultScreen
            scanId={scanId}
            subScanId={subScanId}
            cropList={crops}
            cropId={selectedVariety.id}
            scanResult={scanData}
            moisture={moisture}
            weight={weight}
            visiblePDfButton={visiblePDfButton}
            isChecked={isChecked}
            saveTestVariety={saveTestVariety}
          />}
        </div>
        <div className='text-align-center'>{selectedCapturedWebpImage && <h4 className='m-t-20'>Preview Image</h4>}</div>
        <div className='d-flex justify-center align-center m-t-10'>
          <div>
            {processedImage && (
              <div className='m-b-10'>
                <img src={URL.createObjectURL(processedImage)} alt="Processed" height="400" width={300} style={{ marginRight: '30px' }} />
                <p className='text-align-center'><b><u>Processed Image</u></b></p>
              </div>
            )}
            {selectedCapturedWebpImage && <div>
              <img src={selectedCapturedWebpImage ? URL.createObjectURL(selectedCapturedWebpImage) : ''} alt="" height="400" width={300} />
              <p className='text-align-center'><b><u>Original Image</u></b></p>
            </div>}
          </div>
        </div>
      </div>
    </div >
  )
}
